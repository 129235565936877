import { AfterViewInit, Component, OnInit } from '@angular/core';

// Lightgallery
import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgRotate from 'lightgallery/plugins/rotate';
import lgVideo from 'lightgallery/plugins/video';
import { DataService, JSettings, PortalSettings } from './services/data.service';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { TacDialog } from './tacdialog/tacdialog.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  walkaroundImgs = [];
  inlineGallery = undefined;
  galleryWidth = '100%';
  language1 = true;
  jsettings: JSettings = undefined;
  portalpass = '';
  preMpiSegmentVal = 'pre';

  helpOpen=false;
  authOpen=false;

  vhcAuthItems: VhcAuthItem[] = [
    new VhcAuthItem({
      name: 'Demo item',
      price: 233.00,
      color: 'danger',
      recommendation: 'Replace wiper blades left',
      images: [
        'https://images.pexels.com/photos/70912/pexels-photo-70912.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260',
        'https://images.pexels.com/photos/9070621/pexels-photo-9070621.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260',
        'https://images.pexels.com/photos/3608541/pexels-photo-3608541.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260'
      ],
      portalid: ''
    })
  ];
  vhcAuthItemsAuthorised: VhcAuthItem[] = [
    new VhcAuthItem({
      name: 'Demo item',
      price: 350,
      recommendation: 'It was over 2000 years old.',
      color: 'warning',
      portalid: ''
    })
  ];
  vhcAuthItemsGreenGrey: VhcAuthItem[] = [
    new VhcAuthItem({
      name: 'Demo item',
      price: 350,
      recommendation: 'It was over 2000 years old.',
      color: 'success',
      portalid: ''
    })
  ];

  redCount = 0;
  amberCount = 0;
  greenCount = 0;
  pckgCount = 0;

  portalRecord = undefined;

  constructor(private supa: DataService, private toastController: ToastController,
              public translate: TranslateService, private modalController: ModalController,
              private loadingController: LoadingController, private http: HttpClient){
    // walkaround_imgs = [
    //   'https://images.pexels.com/photos/120049/pexels-photo-120049.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    //   'https://images.pexels.com/photos/909907/pexels-photo-909907.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    // ]
    //translate.addLangs(['en','ar']);
    // let defaultlang = ((window.navigator as any).userLanguage || window.navigator.language).startsWith('en') ? 'en' : 'ar'
    const defaultlang = 'en';
    // if(localStorage.getItem('USERLANGUAGE')!=null)
    // {
    //   defaultlang = localStorage.getItem('USERLANGUAGE');
    // }
    this.language1 = defaultlang==='en';
    // translate.addLangs(['en', 'ar']);
    translate.addLangs(['en']);
    translate.setDefaultLang(defaultlang);
    translate.use(defaultlang);
  }

  cardClick(item: VhcAuthItem, event=null) {
    if (event == null) {
      item.isChecked = !item.isChecked;
    } else {
      const classList = Array.from(event.target.classList);
      if (!(classList.length === 0 || classList.includes('button'))) {
        item.isChecked = !item.isChecked;
      }
      // else image..
    }
  }

  cardImgClick(item: VhcAuthItem, index) {
    let imcount = 0;
    const imageCountArr = [
      ...this.vhcAuthItems,
      ...this.vhcAuthItemsAuthorised,
      ...this.vhcAuthItemsGreenGrey
    ].map(i => (i.images.length + (i.vehiclevisuals ? 1 : 0)));

    for(let i = 0; i < index; i++) {
      imcount += imageCountArr[i];
    }
    console.log(imcount + ' images before');
    if (this.portalRecord.drawings.length > 0) {
      // add 1 to account for the drawing cause it seems to be present
      imcount+= this.portalRecord.drawings.length;
    }
    console.log('Drawings: ' + this.portalRecord.drawings.length);
    if (this.walkaroundImgs.length > 0) {
      // add 1 to account for the walkaround cause it seems to be present
      imcount++;
    }
    if (this.inlineGallery) {
      this.inlineGallery.slide(imcount);
      document.getElementById('lg-container-1').className = document.getElementById('lg-container-1').className.replace('lg-inline','');
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.initData();
  }

  checkDayOfWeek(checknum) {
    if (checknum === 0) { // sunday
      return this.portalRecord.portalsettings.includeSunday;
    } else if (checknum === 1) {
      return this.portalRecord.portalsettings.includeMonday;
    } else if (checknum === 2) {
      return this.portalRecord.portalsettings.includeTuesday;
    } else if (checknum === 3) {
      return this.portalRecord.portalsettings.includeWednesday;
    } else if (checknum === 4) {
      return this.portalRecord.portalsettings.includeThursday;
    } else if (checknum === 5) {
      return this.portalRecord.portalsettings.includeFriday;
    } else if (checknum === 6) {
      return this.portalRecord.portalsettings.includeSaturday;
    }
  }

  getPickupText() {
    const now = new Date();
    const nowWithExpectedTime = new Date(now.getTime() +
          (((this.portalRecord.portalsettings.expectedHours*60)+(this.portalRecord.portalsettings.expectedMinutes))*60000));
    const todayPickup = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      this.portalRecord.portalsettings.pickupHour,
      this.portalRecord.portalsettings.pickupMinutes
    );
    let tries = 50;
    let run = true;
    while (tries >= 0 && run) {
      const h = todayPickup.getHours();
      todayPickup.setDate(todayPickup.getDate() + 1);
      todayPickup.setHours(h);
      if (nowWithExpectedTime.getTime() < todayPickup.getTime() && this.checkDayOfWeek(todayPickup.getDay())) {
        run = false;
      }
      tries--;
    }
    if (tries < 0) {
      return 'Could not calculate the expected pickup time. Please contact our service center for an estimation.';
    }
    const text = 'The car is expected to be ready for pickup on: '
                  + todayPickup.toLocaleDateString() + ' ' + todayPickup.toLocaleTimeString();
    return text;
  }

  async initData() {
    this.jsettings = await this.supa.Settings();
    const portalpasskey = location.search.substr(3);
    if (portalpasskey.length !== 36) {
      setTimeout(()=>{
        this.portalRecord = {vin: 'Data not found'};
      }, 2000);
    } else {
      this.supa.supabase.rpc('get_portal_data', { portalpass: portalpasskey }).single().then(v => {
        if (v.error) {
          this.portalRecord = {vin: 'Data not found'};
        } else {
          this.portalpass = portalpasskey;
          this.portalRecord = v.data;
          this.setAuthItemsAndGallery();
        }
      }, r => {
        this.portalRecord = {vin: 'Data not found'};
      });
    }
  }

  async initGallery() {
    const imgs = await this.getImages();
    const lgContainer = document.getElementById('inline-gallery-container');
    this.inlineGallery = lightGallery(lgContainer, {
      container: lgContainer,
      dynamic: true,
      hash: false,
      licenseKey: 'EDD76D24-635C45C2-BC2F227A-0B59D8C3',
      alignThumbnails: 'middle',
      flipVertical: false,
      flipHorizontal: false,
      closable: false,
      showMaximizeIcon: true,
      appendSubHtmlTo: '.lg-item',
      slideDelay: 0,
      plugins: [lgZoom, lgThumbnail, lgRotate, lgVideo],
      dynamicEl: imgs
    });
    setTimeout(()=>{
      this.inlineGallery.openGallery();
    }, 500);
  }

  setAuthItemsAndGallery() {
    this.vhcAuthItems = [];
    this.vhcAuthItemsAuthorised = [];
    this.vhcAuthItemsGreenGrey = [];
    this.portalRecord.mpimodules.forEach(element => {
      element.thisisfrommpi = true;
    });
    ([...this.portalRecord.premodules, ...this.portalRecord.mpimodules, ...this.portalRecord.qimodules]).forEach((v,i) => {
      const itemismpi = Object.keys(v).includes('thisisfrommpi');
      if (v.type === 'Questionblock') {
        v.questions.forEach((m,mi) => {
          if((m.value+'' === '1' || m.value+'' === '2') && !m.dateAuthorised) {
            this.vhcAuthItems.push(VhcAuthItem.fromModule(m, itemismpi));
          } else if (m.dateAuthorised) {
            this.vhcAuthItemsAuthorised.push(VhcAuthItem.fromModule(m, itemismpi));
          } else {
            this.vhcAuthItemsGreenGrey.push(VhcAuthItem.fromModule(m, itemismpi));
          }
        });
      } else if (v.type === 'Tyres') {
        v.data.tyres.forEach((m,mi) => {
          m.name2 = 'إطار العجلة ' + m.name + ', ' +  m.size + '.' + m.loadindex;
          m.name = 'Tyre ' + m.name + ', ' + m.size + '.' + m.loadindex;
          if((m.value+'' === '1' || m.value+'' === '2') && !m.dateAuthorised) {
            this.vhcAuthItems.push(VhcAuthItem.fromModule(m,itemismpi));
          } else if (m.dateAuthorised) {
            this.vhcAuthItemsAuthorised.push(VhcAuthItem.fromModule(m,itemismpi));
          } else {
            this.vhcAuthItemsGreenGrey.push(VhcAuthItem.fromModule(m,itemismpi));
          }
        });
      } else if (v.type === 'Brakes') {
        v.data.brakes.forEach((m,mi) => {
          m.name2 = 'الفرامل ' + m.name;
          m.name = 'Brake disc & pad ' + m.name;
          if((m.value+'' === '1' || m.value+'' === '2') && !m.dateAuthorised) {
            this.vhcAuthItems.push(VhcAuthItem.fromModule(m,itemismpi));
          } else if (m.dateAuthorised) {
            this.vhcAuthItemsAuthorised.push(VhcAuthItem.fromModule(m,itemismpi));
          } else {
            this.vhcAuthItemsGreenGrey.push(VhcAuthItem.fromModule(m,itemismpi));
          }
        });
      } else if (v.type === 'Cross_sell') {
        v.data.forEach((m,mi) => {
          if(!m.dateAuthorised) {
            this.vhcAuthItems.push(VhcAuthItem.fromModule(m,itemismpi));
          } else {
            this.vhcAuthItemsAuthorised.push(VhcAuthItem.fromModule(m,itemismpi));
          }
        });
      }
    });

    this.vhcAuthItems.sort((a,b) => this.colorToPrio(a.color) - this.colorToPrio(b.color));
    this.vhcAuthItemsAuthorised.sort((a,b) => this.colorToPrio(a.color) - this.colorToPrio(b.color));
    this.vhcAuthItems.sort((a,b) => this.colorToPrio(a.color) - this.colorToPrio(b.color));

    let reds = 0;
    let greens = 0;
    let ambers = 0;
    let pckgs = 0;
    // eslint-disable-next-line max-len
    this.vhcAuthItems.forEach((v,i) => {this.vhcAuthItems[i].isChecked = true; if(v.color === 'danger') { reds++; } else if(v.color === 'success') { greens++; } else if(v.color === 'primary') { pckgs++; } else if(v.color === 'warning') { ambers++; }});
    // eslint-disable-next-line max-len
    this.vhcAuthItemsAuthorised.forEach(v => {if(v.color === 'danger') { reds++; } else if(v.color === 'success') { greens++; } else if(v.color === 'primary') { pckgs++; } else if(v.color === 'warning') { ambers++; }});
    // eslint-disable-next-line max-len
    this.vhcAuthItemsGreenGrey.forEach(v => {if(v.color === 'danger') { reds++; } else if(v.color === 'success') { greens++; } else if(v.color === 'primary') { pckgs++; } else if(v.color === 'warning') { ambers++; }});
    this.redCount = reds;
    this.greenCount = greens;
    this.amberCount = ambers;
    this.pckgCount = pckgs;

    if (Array.isArray(this.portalRecord.walkaround)) {
      // if the walk around does not contain any NULL then laod it.
      if (this.portalRecord.walkaround.findIndex(i => i == null) === -1) {
        this.walkaroundImgs = this.portalRecord.walkaround;
      }
    }

    // save some memory..
    delete this.portalRecord.premodules;
    delete this.portalRecord.mpimodules;
    delete this.portalRecord.qimodules;
    delete this.portalRecord.walkaround;

    console.log(this.vhcAuthItems);

    setTimeout(()=>{this.initGallery();}, 500);
  }

  totalAddedPrice() {
    return this.vhcAuthItems.filter(v => v.isChecked).reduce((a,b)=>(a + Number.parseFloat(b.price+'')), 0);
  }

  totalAuthPrice(): number {
    return this.vhcAuthItemsAuthorised.reduce((a,b)=>(a + Number.parseFloat(b.price+'')), 0);
  }

  totalNewPrice() {
    return this.totalAddedPrice() + this.totalAuthPrice();
  }

  async sendCsat(csat, idx) {
    const loading = await this.loadingController.create({
      message: this.translate.instant('uploading_authorised_items'),
      spinner: 'bubbles'
    });
    await loading.present();

    this.supa.supabase.rpc('submit_portal_csat', {csatdata: {
      portalid: csat.data.portalid,
      portalpass: this.portalpass,
      value: csat.data.value,
      message: csat.data.message
    }}).then(async v => {
      const toast = await this.toastController.create({
        message: this.translate.instant('could_not_upload_authorisation')+'.',
        duration: 5000,
        color: 'danger',
      });
      if (v.error) {
        console.log(v.error);
      } else {
        toast.message = 'Thank you for your feedback!';
        toast.color = 'success';
        this.portalRecord.csat.splice(idx, 1);
      }
      toast.present();
      loading.dismiss();
    });
  }

  async submitData() {
    const checkedItems = this.vhcAuthItems.filter(i => i.isChecked).map(i => i.portalid);
    const modal = await this.modalController.create({
      component: TacDialog,
      componentProps: { isPrimaryLang: this.language1 }
    });
    await modal.present();
    const {data} = await modal.onDidDismiss();
    if (data) {
      const loading = await this.loadingController.create({
        message: this.translate.instant('uploading_authorised_items'),
        spinner: 'bubbles'
      });
      await loading.present();

      this.supa.supabase.rpc('submit_portal_data', {portaldata: {
        datetime: this.getDateTime(),
        portalpass: this.portalpass,
        authorised: checkedItems
      }}).then(async v => {
        loading.dismiss();
        console.log(v);
        if (v.error) {
          const toast = await this.toastController.create({
            message: this.translate.instant('could_not_upload_authorisation')+'.',
            duration: 5000,
            color: 'danger',
          });
          toast.present();
        } else {
          location.reload();
        }
      });
    }
  }

  async setLang(ln: string) {
    localStorage.setItem('USERLANGUAGE', ln);
    this.language1 = ln === 'en';
    this.translate.use(ln);
  }

  async convertItems(items) {
    const images = [];
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < items.length; i++) {
      items[i].images.forEach((img, index) => {
        images.push({
          src: img,
          thumb: img,
          subHtml: `<div class="lightGallery-captions">
              <p class="gallery-caption">${items[i].name} (${index+1}/${items[i].images.length})</p>
          </div>`,
        });
      });
      items[i].videos.forEach(async (vid, index) => {
        images.push({
          src: (await this.supa.supabase.storage.from('internaldesign').createSignedUrl(vid, 600)).signedURL,
          thumb: '/assets/playvideo.png',
          subHtml: `<div class="lightGallery-captions">
              <p class="gallery-caption">${items[i].name} (${index+1}/${items[i].images.length})</p>
          </div>`,
        });
      });
      items[i].audio.forEach(async (aud, index) => {
        images.push(
          {
            src: aud,
            thumb: '/assets/playvideo.png',
            iframe: true
          });
      });
      if (items[i].vehiclevisuals) {
        const videoData = await this.getVisualsData(items[i].vehiclevisuals);
        images.push({
          src: videoData.video_url,
          thumb: videoData.banner_url,
          iframe: true
        });
      }
    }
    console.log(images);
    return images;
  }

  async getVisualsData(pid: string) {
    const result = await this.supa.supabase.rpc('get_vehicle_visuals_url', {id: pid}).single();
    return result.data;
  }

  async getImages() {
    const images = [];
    if (this.walkaroundImgs.length > 0) {
      images.push({
        src: '/assets/walkaround.html',
        thumb: 'assets/3dwalkaround.png',
        iframe: true
      });
    }
    images.push(...this.portalRecord.drawings.map(d => ({
      src: d,
      thumb: d,
      subHtml: `<div class="lightGallery-captions"><p class="gallery-caption">Drawing</p></div>`
    })));
    images.push(...(await this.convertItems(this.vhcAuthItems)));
    images.push(...(await this.convertItems(this.vhcAuthItemsAuthorised)));
    images.push(...(await this.convertItems(this.vhcAuthItemsGreenGrey)));
    if (images.length === 0) {
      images.push({
        src: 'https://iqmcdn.ams3.digitaloceanspaces.com/iqm_assets/evhc/portal/starry_sky.jpeg',
        thumb: 'https://iqmcdn.ams3.digitaloceanspaces.com/iqm_assets/evhc/portal/starry_sky_thumb.png',
      });
    }
    return images;
  }

  public getDateTime() {
    const today = new Date();
    // eslint-disable-next-line max-len
    return today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate() + ' ' + today.getHours().toString().padStart(2,'0') + ':' + today.getMinutes().toString().padStart(2,'0');
  }

  colorToPrio(c) {
    if (c === 'danger') {
      return 0;
    } else if (c === 'warning') {
      return 1;
    }
    return 2;
  }

}

export class VhcAuthItem implements IVhcAuthItem {
  public name = '';
  public name2 = '';
  public recommendation = '';
  public recommendation2 = '';
  public images: string[] = [];
  public isChecked = false;
  public price = 100;
  public color = 'primary';
  public portalid = '';
  public isMpi = false;
  public vehiclevisuals = '';

  constructor(options: (IVhcAuthItem|null)=null) {
    if (options) {
      Object.keys(options).forEach(k => {
        this[k] = options[k];
      });
    }
  }

  static fromModule(m,itwasmpi=false) {
    let color = '';
    switch (m.value+'') {
      case '3':
        color = 'success';
        break;
      case '2':
        color = 'warning';
        break;
      case '1':
        color = 'danger';
        break;
      case '0':
        color = 'medium';
        break;
      default:
        color = 'primary';
        break;
    }
    let p = Number.parseFloat(m.price+'');
    if(Number.isNaN(p)) {
      p = 0;
    }
    return new VhcAuthItem({
      name: m.name,
      name2: m.name2,
      price: p,
      recommendation: m.recommendation,
      recommendation2: m.recommendation2,
      color,
      portalid: m.portalid,
      images: Array.isArray(m.images) ? m.images : [],
      videos: Array.isArray(m.videos) ? m.videos : [],
      audio: Array.isArray(m.audio) ? m.audio : [],
      isMpi: itwasmpi,
      vehiclevisuals: m.vehiclevisuals
    });
  }

}

export class IVhcAuthItem {
  price?: number;
  color?: string;
  name?: string;
  name2?: string;
  recommendation?: string;
  recommendation2?: string;
  images?: string[];
  videos?: string[];
  audio?: string[];
  isChecked?: boolean;
  isMpi?: boolean;
  portalid: string;
  vehiclevisuals?: string;
}


// https://images.pexels.com/photos/70912/pexels-photo-70912.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260
// https://images.pexels.com/photos/3608541/pexels-photo-3608541.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260
// https://images.pexels.com/photos/9070621/pexels-photo-9070621.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260
