// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const sid = "uowfxqrcdvurtakulnwq";

export const environment = {
  production: false,
  supabaseUrl: 'https://' + sid + '.supabase.co',
  supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvd2Z4cXJjZHZ1cnRha3VsbndxIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzA4MTExMjUsImV4cCI6MjA0NjM4NzEyNX0.Fd-jn1IJzc6b5QXsqBZCtbapYowSKotgfmVYBjGo82Y',
  SettingsExpiretime: 600000 // 10*60*1000 = 10 minutes
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
